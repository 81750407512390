<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">
          {{ title + convenio.value }}
        </span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="tiposComprobantes"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="4" align="end">
                <v-btn color="primary" @click="openModal()" class="to-right">
                  Nuevo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openModal(item.ivaDevengaId)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar registro</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openModalDelete(item.ivaDevengaId)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar registro</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog
        v-if="openModalEdit"
        v-model="openModalEdit"
        max-width="60%"
        @keydown.esc="closeAndReload"
        persistent
      >
        <EditTipoComprobante
          :ivaDevengaIdToCopy="ivaDevengaIdToCopy"
          :ivaDevengaId="this.ivaDevengaId"
          :convenioId="convenio.id"
          @closeAndReload="closeAndReload"
        ></EditTipoComprobante>
      </v-dialog>
      <DeleteDialog
        :titleProp="titleDelete"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="confirmDelete()"
      />
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined @click="closeModal" class="to-right mr-2"> Cerrar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import EditTipoComprobante from "@/components/modules/cuotas/configuracion/EditTipoComprobante.vue";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  components: { EditTipoComprobante, DeleteDialog },
  name: "ComprobantesPorOS",
  props: {
    convenio: {
      type: Object,
      required: true,
    },
  },
  data: (vm) => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    showDeleteModal: false,
    idToDelete: null,
    convenioSelected: null,
    convenios: [],
    tipoIvaSelected: null,
    searchIcon: enums.icons.SEARCH,
    search: "",
    tiposIva: [],
    routeToGo: "ProcesosDevengamiento",
    title: "Comprobantes configurados en: ",
    checkIcon: enums.icons.CHECK_OUTLINE,
    ivaDevengaIdToCopy: null,
    titleDelete: "¿Eliminar registro?",
    rules: rules,
    tiposComprobantes: [],
    ivaDevengaId: null,
    headers: [
      {
        text: "Condición de IVA",
        align: "start",
        value: "ivaNom",
      },
      {
        text: "Comprobante de devengamiento",
        align: "start",
        value: "tipoCmpDevenga",
      },
      {
        text: "Comprobante de cobranza",
        align: "start",
        value: "tipoCmpCobranza",
      },
      {
        text: "N° sucursal",
        align: "start",
        value: "nroSuc",
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
    ],
    openModalEdit: false,
    modalCopyConfig: false,
    modalValidateAction: false,
  }),
  created() {
    this.loadTiposComprobantes();
  },
  methods: {
    ...mapActions({
      getTiposComprobantesByConvenio:
        "configuracion/getTiposComprobantesByConvenio",
      deleteTipoComprobante: "configuracion/deleteTipoComprobante",
      setAlert: "user/setAlert",
    }),
    async loadTiposComprobantes() {
      const data = await this.getTiposComprobantesByConvenio(this.convenio.id);
      this.tiposComprobantes = data;
    },
    openModalDelete(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteTipoComprobante(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadTiposComprobantes();
      }
    },
    openModal(id) {
      this.openModalEdit = true;
      this.ivaDevengaId = id;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    async updateTiposComprobantes() {
      const filters = {
        convenioId: this.convenioSelected,
        ivaId: this.tipoIvaSelected,
      };
      const response = await this.fetchTiposComprobantesByFilters(filters);
      this.tiposComprobantes = response;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadTiposComprobantes();
    },
    openModalCopyConfig(id) {
      this.modalCopyConfig = true;
      this.ivaDevengaId = id;
    },
    selectConfigToCopy(id) {
      this.ivaDevengaIdToCopy = id;
      this.modalValidateAction = true;
    },
  },
};
</script>

<style scoped>
</style>
