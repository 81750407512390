var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{attrs:{"title":_vm.title}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.convenios,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.hasConfig",fn:function(ref){
var item = ref.item;
return [(item.hasConfig)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canCopy)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModalCopyConfig(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.copyIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Copiar configuración")])]):_vm._e(),(_vm.canConfig)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.configIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Configurar")])]):_vm._e()]}}],null,true)}),(_vm.openModalComprobantesXOs)?_c('v-dialog',{attrs:{"max-width":"70%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload($event)}},model:{value:(_vm.openModalComprobantesXOs),callback:function ($$v) {_vm.openModalComprobantesXOs=$$v},expression:"openModalComprobantesXOs"}},[_c('ComprobantesPorOS',{attrs:{"convenio":_vm.convenioSelected},on:{"closeAndReload":_vm.closeAndReload}})],1):_vm._e(),(_vm.modalCopyConfig)?_c('v-dialog',{attrs:{"max-width":"70%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload($event)}},model:{value:(_vm.modalCopyConfig),callback:function ($$v) {_vm.modalCopyConfig=$$v},expression:"modalCopyConfig"}},[_c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"primary--text"},[_vm._v("Seleccione el convenio al que desea asignarle la configuración seleccionada")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.conveniosToCopy},scopedSlots:_vm._u([{key:"item.hasConfig",fn:function(ref){
var item = ref.item;
return [(item.hasConfig)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.selectConfigToCopy(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.selectIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Seleccionar registro")])])]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"to-rigth mr-2",attrs:{"outlined":""},on:{"click":_vm.closeAndReload}},[_vm._v(" Cancelar ")])],1)],1)],1)],1):_vm._e(),(_vm.modalValidateAction)?_c('v-dialog',{attrs:{"max-width":"55%"},model:{value:(_vm.modalValidateAction),callback:function ($$v) {_vm.modalValidateAction=$$v},expression:"modalValidateAction"}},[_c('v-card',[_c('v-container',[_c('v-card-text',[_c('v-card-title',{staticClass:"headline primary--text"},[_vm._v(" ¿Desea eliminar la configuración existente del registro seleccionado? ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeAndReload()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary","dark":""},on:{"click":function($event){return _vm.deleteConfigAndCopy()}}},[_vm._v(" Confirmar ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }