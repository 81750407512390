<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-data-table
      :headers="headers"
      :items="convenios"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="search"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.hasConfig`]="{ item }">
        <v-icon v-if="item.hasConfig" small color="primary">
          {{ checkIcon }}
        </v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left v-if="canCopy">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="20"
              v-on="on"
              @click="openModalCopyConfig(item)"
            >
              {{ copyIcon }}
            </v-icon>
          </template>
          <span>Copiar configuración</span>
        </v-tooltip>
        <v-tooltip left v-if="canConfig">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" size="20" v-on="on" @click="openModal(item)">
              {{ configIcon }}
            </v-icon>
          </template>
          <span>Configurar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-if="openModalComprobantesXOs"
      v-model="openModalComprobantesXOs"
      max-width="70%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <ComprobantesPorOS
        :convenio="convenioSelected"
        @closeAndReload="closeAndReload"
      />
    </v-dialog>
    <v-dialog
      v-if="modalCopyConfig"
      v-model="modalCopyConfig"
      max-width="70%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title class="primary--text"
            >Seleccione el convenio al que desea asignarle la configuración
            seleccionada</v-card-title
          >
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="conveniosToCopy"
              class="elevation-1"
            >
              <template v-slot:[`item.hasConfig`]="{ item }">
                <v-icon v-if="item.hasConfig" small color="primary">
                  {{ checkIcon }}
                </v-icon>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="selectConfigToCopy(item)"
                    >
                      {{ selectIcon }}
                    </v-icon>
                  </template>
                  <span>Seleccionar registro</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined @click="closeAndReload" class="to-rigth mr-2">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="modalValidateAction"
      v-model="modalValidateAction"
      max-width="55%"
    >
      <v-card>
        <v-container>
          <v-card-text>
            <v-card-title class="headline primary--text">
              ¿Desea eliminar la configuración existente del registro
              seleccionado?
            </v-card-title>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeAndReload()"> Cancelar </v-btn>
            <v-btn text color="primary" dark @click="deleteConfigAndCopy()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card></v-dialog
    >
  </v-container>
</template>

<script>
import ComprobantesPorOS from "@/components/modules/cuotas/configuracion/ComprobantesPorOS.vue";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  components: { PageHeader, GoBackBtn, ComprobantesPorOS },
  name: "TiposComprobantes",
  data: () => ({
    openModalComprobantesXOs: false,
    configIcon: enums.icons.SETTINGS,
    copyIcon: enums.icons.COPY,
    convenioSelected: null,
    convenios: [],
    searchIcon: enums.icons.SEARCH,
    search: "",
    routeToGo: "DevengamientosCuotas",
    title: enums.titles.TIPOS_COMPROBANTES_DEVENGAMIENTO,
    checkIcon: enums.icons.CHECK_OUTLINE,
    selectIcon: enums.icons.CHECK,
    rules: rules,
    headers: [
      {
        text: "Convenio",
        align: "start",
        value: "value",
        sortable: false,
      },
      {
        text: "Tiene configuración",
        align: "center",
        value: "hasConfig",
        sortable: false,
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
    ],
    allowedActions: null,
    modalCopyConfig: false,
    modalValidateAction: false,
    canCopy: false,
    canConfig: false,
    convenioSelectedToCopy: null,
    conveniosToCopy: [],
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.setConvenios();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getTiposComprobantesByConvenio:
        "configuracion/getTiposComprobantesByConvenio",
      getConveniosAndConfig: "configuracion/getConveniosAndConfig",
      copyConfigTiposComprobantes: "configuracion/copyConfigTiposComprobantes",
      setAlert: "user/setAlert",
    }),
    async setConvenios() {
      const convenios = await this.getConveniosAndConfig();
      this.convenios = convenios;
    },
    setPermisos() {
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .CONFIGURAR_TIPOS_COMPROBANTES:
            this.canConfig = true;
            break;
          case enums.modules.adminSistema.permissions.COPIAR_TIPO_COMPROBANTE:
            this.canCopy = true;
            break;
          default:
            break;
        }
      });
    },
    openModal(item) {
      this.openModalComprobantesXOs = true;
      this.convenioSelected = item;
    },
    closeAndReload() {
      this.openModalComprobantesXOs = false;
      this.modalCopyConfig = false;
      this.modalValidateAction = false;
      this.setConvenios();
    },
    openModalCopyConfig(item) {
      this.conveniosToCopy = this.convenios.filter((x) => x.id != item.id);
      this.modalCopyConfig = true;
      this.convenioSelected = item;
    },
    async selectConfigToCopy(item) {
      this.convenioSelectedToCopy = item;
      if (item.hasConfig) {
        this.modalValidateAction = true;
      } else {
        const data = {
          convenioOrigenId: this.convenioSelected.id,
          convenioDestinoId: this.convenioSelectedToCopy.id,
        };
        const res = await this.copyConfigTiposComprobantes(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.closeAndReload();
        }
      }
    },
    async deleteConfigAndCopy() {
      const data = {
        convenioOrigenId: this.convenioSelected.id,
        convenioDestinoId: this.convenioSelectedToCopy.id,
      };
      const res = await this.copyConfigTiposComprobantes(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeAndReload();
      }
    },
  },
};
</script>

<style scoped>
</style>
