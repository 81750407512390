<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" md="6" sm="4" class="py-0">
              <v-autocomplete
                v-model="convenioSelected"
                :items="convenios"
                label="Convenio"
                disabled
                :rules="rules.required"
                item-text="value"
                item-value="id"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="4"
              class="py-0"
              v-if="ivaDevengaId == null"
            >
              <v-select
                v-model="tiposIvaMultiSelected"
                :items="tiposIva"
                outlined
                dense
                label="Condición de IVA (*)"
                multiple
                :rules="[tiposIvaMultiSelected.length != 0]"
                item-text="value"
                item-value="id"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="tipoCondIvaToggle()">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          tiposIvaMultiSelected.length > 0 ? 'primary' : ''
                        "
                      >
                        {{ multiselectIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ tiposIvaMultiSelected.length - 1 }} otros)
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="4"
              class="py-0"
              v-if="ivaDevengaId != null"
            >
              <v-autocomplete
                v-model="tipoIvaSelected"
                :items="tiposIva"
                label="Condición de IVA (*)"
                item-text="value"
                item-value="id"
                :rules="rules.required"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" sm="4" class="py-0">
              <v-text-field
                v-model="nroSucursal"
                label="N° sucursal (*)"
                type="text"
                dense
                :rules="rules.required"
                outlined
                v-mask="'####'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="4" class="py-0">
              <v-select
                v-model="entidadFacturanteSelected"
                :items="entidadesFacturantes"
                label="Entidad facturante (*)"
                item-value="id"
                item-text="value"
                @change="updateTiposComprobante()"
                :rules="rules.required"
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" sm="4" class="py-0">
              <v-autocomplete
                v-model="comprobanteDevengamientoSelected"
                :items="comprobantesDeuda"
                label="Comp. para devengar (*)"
                clearable
                :rules="rules.required"
                item-text="value"
                item-value="id"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" sm="4" class="py-0">
              <v-autocomplete
                v-model="comprobanteCobranzaSelected"
                :items="comprobantesCancelacion"
                label="Comp. para el cobro automático (*)"
                clearable
                item-text="value"
                :rules="rules.required"
                item-value="id"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" sm="4" class="py-0">
              <v-autocomplete
                v-model="comprobanteCancDevengaSelected"
                :items="comprobantesCancelacion"
                label="Comp. para revertir un devengamiento (*)"
                clearable
                :rules="rules.required"
                item-text="value"
                item-value="id"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" sm="4" class="py-0">
              <v-autocomplete
                v-model="comprobanteCancAporteSelected"
                :items="comprobantesCancelacion"
                label="Comp. para descontar aportes"
                clearable
                item-text="value"
                item-value="id"
                :rules="
                  comprobanteCancelaDifSelected != null ||
                  comprobanteDevengamientoDifSelected != null
                    ? rules.required
                    : []
                "
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" sm="4" class="py-0">
              <v-autocomplete
                v-model="comprobanteCancelaDifSelected"
                :items="comprobantesCancelacion"
                label="Comp. para la diferencia a facturar"
                clearable
                item-text="value"
                item-value="id"
                outlined
                dense
                :rules="
                  comprobanteCancAporteSelected != null ||
                  comprobanteDevengamientoDifSelected != null
                    ? rules.required
                    : []
                "
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" sm="4" class="py-0">
              <v-autocomplete
                v-model="comprobanteDevengamientoDifSelected"
                :items="comprobantesDeuda"
                label="Comp. para devengar la diferencia a facturar"
                clearable
                item-text="value"
                item-value="id"
                outlined
                dense
                :rules="
                  comprobanteCancAporteSelected != null ||
                  comprobanteCancelaDifSelected != null
                    ? rules.required
                    : []
                "
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";

export default {
  name: "EditTipoComprobante",
  directives: { mask },
  props: ["ivaDevengaId", "ivaDevengaIdToCopy", "convenioId"],
  data: () => ({
    isFormValid: false,
    comprobanteDevengamientoDifSelected: null,
    comprobanteCancelaDifSelected: null,
    comprobanteCancAporteSelected: null,
    comprobanteCancDevengaSelected: null,
    comprobanteCobranzaSelected: null,
    formEditTitle:
      "Editar configuración de tipos de comprobantes para devengar",
    rules: rules,
    convenios: [],
    convenioSelected: null,
    tiposIva: [],
    tipoIvaSelected: null,
    tiposIvaMultiSelected: [],
    comprobantesDeuda: [],
    comprobantesCancelacion: [],
    comprobanteDevengamientoSelected: null,
    nroSucursal: null,
    entidadFacturanteSelected: null,
    entidadesFacturantes: []
  }),
  created() {
    if (this.ivaDevengaIdToCopy != null) {
      this.setConfigToCopy();
    }
    if (this.ivaDevengaId != null && this.ivaDevengaIdToCopy == null) {
      this.setTipoComprobante();
    } else {
      this.newTipoComprobante();
    }
    this.convenioSelected = this.convenioId;
    this.setSelects();
  },
  computed: {
    multiselectIcon() {
      if (this.selectAlltiposCondIva) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAlltiposCondIva() {
      return (
        this.tiposIvaMultiSelected &&
        this.tiposIvaMultiSelected.length === this.tiposIva.length
      );
    }
  },
  methods: {
    ...mapActions({
      getTipoComprobanteById: "configuracion/getTipoComprobanteById",
      postTiposComprobantes: "configuracion/postTiposComprobantes",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      getCompCtaCteAndTipo: "configuracion/getCompCtaCteAndTipo",
      getConvenios: "afiliaciones/getConvenios",
      getTiposIva: "afiliaciones/getTiposIva",
      setAlert: "user/setAlert"
    }),
    async setConfigToCopy() {
      const response = await this.getTipoComprobanteById(
        this.ivaDevengaIdToCopy
      );
      this.convenioSelected = response.convenio;
      const configToCopy = await this.getTipoComprobanteById(this.ivaDevengaId);
      this.tipoIvaSelected = configToCopy.iva;
      this.comprobanteCancDevengaSelected = configToCopy.tipoCmpCancelaDevenga;
      this.comprobanteCancelaDifSelected = configToCopy.tipoCmpCancelaDifXApEsp;
      this.comprobanteCancAporteSelected = configToCopy.tipoCmpCancelaXApEsp;
      this.comprobanteCobranzaSelected = configToCopy.tipoCmpCobranza;
      this.comprobanteDevengamientoSelected = configToCopy.tipoCmpDevenga;
      this.comprobanteDevengamientoDifSelected =
        configToCopy.tipoCmpDevengaDifXApEsp;
    },
    async setSelects() {
      const convenios = await this.getConvenios();
      this.convenios = convenios;
      const tiposIva = await this.getTiposIva();
      this.tiposIva = tiposIva;
      const entsFacs = await this.getEntidadesFacturantes();
      this.entidadesFacturantes = entsFacs;
    },
    async setTipoComprobante() {
      const response = await this.getTipoComprobanteById(this.ivaDevengaId);
      this.convenioSelected = response.convenio;
      this.entidadFacturanteSelected = response.entFacId;
      this.updateTiposComprobante();
      this.tipoIvaSelected = response.iva;
      this.comprobanteCancDevengaSelected = response.tipoCmpCancelaDevenga;
      this.comprobanteCancelaDifSelected = response.tipoCmpCancelaDifXApEsp;
      this.comprobanteCancAporteSelected = response.tipoCmpCancelaXApEsp;
      this.comprobanteCobranzaSelected = response.tipoCmpCobranza;
      this.comprobanteDevengamientoSelected = response.tipoCmpDevenga;
      this.comprobanteDevengamientoDifSelected =
        response.tipoCmpDevengaDifXApEsp;
      this.nroSucursal = response.nroSuc;
    },
    async newTipoComprobante() {
      this.formEditTitle =
        "Nueva configuración de tipos de comprobantes para devengar";
    },
    async saveEdit() {
      const data =
        this.ivaDevengaId === null || this.ivaDevengaId === undefined
          ? {
              ivaDevengaId: this.ivaDevengaId,
              convenio: this.convenioSelected,
              listIvaId: this.tiposIvaMultiSelected,
              nroSuc: this.nroSucursal,
              tipoCmpDevenga: this.comprobanteDevengamientoSelected,
              tipoCmpCobranza: this.comprobanteCobranzaSelected,
              tipoCmpCancelaDevenga: this.comprobanteCancDevengaSelected,
              tipoCmpCancelaXApEsp: this.comprobanteCancAporteSelected,
              tipoCmpCancelaDifXApEsp: this.comprobanteCancelaDifSelected,
              tipoCmpDevengaDifXApEsp: this.comprobanteDevengamientoDifSelected
            }
          : {
              ivaDevengaId: this.ivaDevengaId,
              convenio: this.convenioSelected,
              iva: this.tipoIvaSelected,
              nroSuc: this.nroSucursal,
              tipoCmpDevenga: this.comprobanteDevengamientoSelected,
              tipoCmpCobranza: this.comprobanteCobranzaSelected,
              tipoCmpCancelaDevenga: this.comprobanteCancDevengaSelected,
              tipoCmpCancelaXApEsp: this.comprobanteCancAporteSelected,
              tipoCmpCancelaDifXApEsp: this.comprobanteCancelaDifSelected,
              tipoCmpDevengaDifXApEsp: this.comprobanteDevengamientoDifSelected
            };
      const res = await this.postTiposComprobantes(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    tipoCondIvaToggle() {
      this.$nextTick(() => {
        if (this.selectAlltiposCondIva) {
          this.tiposIvaMultiSelected = [];
        } else {
          this.tiposIvaMultiSelected = this.tiposIva;
          this.tiposIvaMultiSelected = this.tiposIva.map(x => x.id);
        }
      });
    },
    updateTiposComprobante() {
      if (
        this.entidadFacturanteSelected !== null &&
        this.entidadFacturanteSelected !== undefined
      ) {
        this.setTipoDeComprobante();
      } else {
        this.comprobantesDeuda = [];
        this.comprobantesCancelacion = [];
      }
    },
    async setTipoDeComprobante() {
      const comprobantes = await this.getCompCtaCteAndTipo(
        this.entidadFacturanteSelected
      );
      this.comprobantesDeuda = comprobantes.filter(x => x.tipo === "D");
      this.comprobantesCancelacion = comprobantes.filter(x => x.tipo === "C");
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep label {
  margin-bottom: 0;
}
</style>
